import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import clsx from "clsx";

import { Paper } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import { toast } from "react-toastify";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useSettings from "../../hooks/useSettings";
import { useSocketListener } from "../../hooks/useSocket/Events";
import useTenant from "../../hooks/useTenant";
import { i18n } from "../../translate/i18n";
import ContactDrawer from "../ContactDrawer";
import MessageInput from "../MessageInput/";
import MessagesList from "../MessagesList";
import { TagsContainer } from "../TagsContainer";
import TicketActionButtons from "../TicketActionButtons";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        height: "100%",
        position: "relative",
        overflow: "hidden",
    },

    ticketInfo: {
        maxWidth: "50%",
        flexBasis: "50%",
        [theme.breakpoints.down('md')]: {
            maxWidth: "unset",
            flexBasis: "unset",
        },
    },
    ticketActionButtons: {
        maxWidth: "50%",
        flexBasis: "50%",
        display: "flex",
        [theme.breakpoints.down('md')]: {
            display: "inline",
            float: "right",
            position: "absolute",
            right: "0",
            marginTop: "5px",
        },
    },
    mainWrapper: {
        flex: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        marginRight: -drawerWidth,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },

    mainWrapperShift: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
}));

const Ticket = () => {
    const { uuid } = useParams();
    const classes = useStyles();

    // State
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [contact, setContact] = useState({});
    const [ticket, setTicket] = useState({});

    // Context
    const { settings, getSettingValue } = useSettings();
    const { tenant, tenantId, userTenant } = useTenant();

    // API
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (!uuid) return;
        setLoading(true);

        const fetchTicket = async () => {
            if (tenantId) {
                try {
                    const { data } = await axiosPrivate.get(`/${tenantId}/tickets/${uuid}`, { signal });
                    const { queueId } = data;
                    const { role, queues } = userTenant;
                    const queueAllowed = queues?.find(q => q.id === queueId);

                    if (queueId && queueAllowed === undefined &&
                        userTenant?.id !== data.userTenantId &&
                        (role !== 'super' ||
                            role === 'admin' ||
                            role === 'supervisor'||
                            role === 'user'
                        )) {
                        toast.error(i18n.t("translation.ticket.toasts.noAccess"));
                        navigate(`/${tenant?.slug}/tickets`);
                        return;
                    }

                    setContact(data.contact);
                    setTicket(data);

                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err, "", "Couldn't fetch ticket from backend at Ticket Component");
                }
            }
        };
        fetchTicket();

        return () => controller.abort();

    }, [axiosPrivate, navigate, tenant, tenantId, userTenant, uuid]);

    // Handlers
    // Handle Contact Changes
    const handleContactChange = (data) => {
        if (data.action === "update") {
            setContact((prevState) => {
                if (prevState.id === data.contact?.id) {
                    return { ...prevState, ...data.contact };
                }
                return prevState;
            });
        }
    };
    useSocketListener(`contact`, (data) => handleContactChange(data))

    // Handle Chat Changes
    const handleChatChange = (data) => {
        if (data.action === "update") {
            setTicket(data.ticket);
        }

        if (data.action === "delete") {
            toast.success(i18n.t("translation.ticketOptionsMenu.confirmationModal.success"));
            navigate(`/${tenant?.slug}/tickets`);
        }
    };
    useSocketListener(`chat:${ticket?.id}`, (data) => handleChatChange(data))

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };


    const checkQueues = ticket => {
        let checkSettings = true;
        const { role, queues } = userTenant;
        const hasPermission = queues.find(q => q.id === ticket.queueId)
        const roles = ["super", "admin", "supervisor", "user"];
        const adminUser = roles.indexOf(role);

        if (!hasPermission && adminUser === -1) {
            checkSettings = false;
        }

        if (!checkSettings &&
            userTenant?.id !== ticket.userTenantId) {
            toastError(i18n.t('translation.backendErrors.ERR_NO_PERMISSION'))
            navigate(`/${tenant?.slug}/tickets`);
            return false
        } else {
            return true
        }
    }

    const handleUpdateTicketStatus = async (e, status, userTenantId) => {
        let isMounted = true;
        const controller = new AbortController();

        if (tenantId) {
            try {
                setLoading(true);
                let data = { status }
                if (status === "open") {
                    data = { ...data, acceptAt: Date.now() }
                };
                if (status !== "pending") {
                    data = { ...data, userTenantId: userTenantId || null }
                };

                await axiosPrivate.put(`/${tenantId}/tickets/${ticket.id}`, data);

                if (isMounted) {
                    setLoading(false)
                }

                if (status === "closed" || status === "waiting" || status === "pending") {
                    navigate(`/${tenant?.slug}/tickets`);
                } else {
                    navigate(`/${tenant?.slug}/tickets/${ticket.uuid}`);
                }
            } catch (err) {
                setLoading(false);
                toastError(err, "", "Couldn't update ticket at Ticket Component");
            }
        }

        return () => {
            isMounted = false;
            controller.abort();
        };
    }

    const handleUpdateBlockCampaigns = async (blockCampaigns) => {
        let isMounted = true;
        const controller = new AbortController();

        if (tenantId) {
            setLoading(true);;

            let values = {
                ...ticket.contact,
                blockCampaigns
            }

            try {
                if (isMounted) setLoading(false)
                await axiosPrivate.put(`/${tenantId}/contacts/${ticket.contact.id}`, values, { signal: controller.signal });
            } catch (err) {
                setLoading(false);
                toastError(err, "", "Couldn't update ticket at Ticket Component while trying to update Block Campaigns to contact data.");
            }
        }

        return () => {
            isMounted = false;
            controller.abort();
        };
    }

    const showTicket = () => {
        return (
            <>
                <TicketHeader loading={loading}>
                    <div className={classes.ticketInfo}>
                        <TicketInfo
                            contact={contact}
                            ticket={ticket}
                            onClick={handleDrawerOpen}
                        />
                    </div>
                    <div className={classes.ticketActionButtons}>
                        <TicketActionButtons
                            ticket={ticket}
                            userTenant={userTenant}
                            handleUpdateTicketStatus={handleUpdateTicketStatus}
                            handleUpdateBlockCampaigns={handleUpdateBlockCampaigns}
                        />
                    </div>
                </TicketHeader>
                <TagsContainer ticket={ticket} />
                <ReplyMessageProvider>
                    <MessagesList
                        ticketId={ticket.id}
                        isGroup={ticket.isGroup}
                    ></MessagesList>
                    <MessageInput
                        ticketId={ticket.id}
                        ticketStatus={ticket.status}
                    />
                </ReplyMessageProvider>
            </>
        )
    }

    return (
        <>
            <div className={classes.root} id="drawer-container">
                <Paper
                    variant="outlined"
                    elevation={0}
                    className={clsx(classes.mainWrapper, {
                        [classes.mainWrapperShift]: drawerOpen,
                    })}
                >
                    {settings?.length > 0 && getSettingValue("restrictToQueues") === "enabled" ? (
                        checkQueues(ticket) ? (
                            ticket && showTicket()
                        ) : '') : (
                        ticket && showTicket()
                    )}

                </Paper>
                <ContactDrawer
                    open={drawerOpen}
                    handleDrawerClose={handleDrawerClose}
                    contact={contact}
                    loading={loading}
                />
            </div>
        </>
    );
};

export default Ticket;
